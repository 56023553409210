import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/layout';
import '../assets/styles/pages/action-single.scss';
import AudioControl from '../components/audio-control';
import { SongConsumer } from '../components/context';

const Action = ({ data, pageContext }) => {
  const action = data.datoCmsAction;
  const displayFeaturedMedia = () => {
    if (action.video) {
      return (
        <div className="responsive-iframe">
          <iframe
            src={`https://player.vimeo.com/video/${action.video.providerUid}`}
            title={action.video.title}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      );
    }

    if (action.featured.fluid !== null && action.featured.filename.split('.').pop() !== 'svg') {
      return <Img fluid={{ ...action.featured.fluid, aspectRatio: 16 / 9 }} />;
    }

    if (action.featured.filename.split('.').pop() === 'svg') {
      return <img src={action.featured.url} alt={action.title} width="100%" />;
    }

    return null;
  };

  return (
    <SongConsumer>
      {(context) => (
        <Layout pagination={pageContext} paginate="actions">
          <HelmetDatoCms seo={action.seoMetaTags} />
          <div className="container">
            <div className="action-single row">
              <div className="one-quarter sidebar">
                {action.title}
                <br />
                {action.name && (
                <strong>
                  {action.name}
                  <br />
                </strong>
                )}
                {action.pieceTitle && (
                <strong>
                  <em>
                    {action.pieceTitle}
                    <br />
                  </em>
                </strong>
                )}
                <br />
                —
                <br />
                {action.tracks.length > 0 && (
                  <div className="tracks">
                    {action.tracks.map((track) => (
                      <div className="track" key={track.id}>
                        <br />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: track.trackInfo,
                          }}
                        />
                        <AudioControl edition={track} context={context} />
                      </div>
                    ))}
                    <br />
                    —
                    <br />
                  </div>
                )}
                <div
                  className="other-info-wrapper"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: action.otherInformation,
                  }}
                />
              </div>
              <div className="two-quarters">
                {displayFeaturedMedia()}
                <div
                  className="action-body-wrapper"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: action.body,
                  }}
                />
                <div className="additional-images-wrapper">
                  {action.additionalImages.map((items) => {
                    if (items.filename.split('.').pop() !== 'svg') {
                      return <Img key={items.fluid.src} fluid={items.fluid} />;
                    }

                    return <img src={items.url} alt={items.filename} width="100%" />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </SongConsumer>
  );
};

export default Action;

export const query = graphql`
  query ActionQuery($slug: String!) {
    datoCmsAction(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      actionType
      title
      productionCompany
      name
      pieceTitle
      date
      otherInformation
      featured {
        fluid(maxWidth: 1100) {
          ...GatsbyDatoCmsSizes
        }
        url
        filename
      }
      video {
        providerUid
        title
      }
      body
      additionalImages {
        fluid(maxWidth: 1100) {
          ...GatsbyDatoCmsSizes
        }
        url
        filename
      }
      slug
      tracks {
        id
        trackInfo
        soundFile
        name
        title
        trackId
      }
    }
  }
`;

Action.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape().isRequired,
};
